:root {
  --bs-font-sans-serif: 'Nunito', sans-serif;
  --bs-body-font-size: 0.9rem;

  // Colors
  --bs-primary-rgb: 114, 124, 245;

  --bs-secondary-color: rgba(108, 117, 125, 0.75);
  --bs-secondary-bg: #fff;

  --bs-primary: #727cf5;
  --bs-secondary: #6c757d;
  --bs-success: #0acf97;
  --bs-info: #39afd1;
  --bs-warning: #ffc35a;
  --bs-danger: #fa5c7c;
  --bs-light: #eef2f7;
  --bs-dark: #313a46;

  --bs-primary-rgb: 114, 124, 245;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 10, 207, 151;
  --bs-info-rgb: 57, 175, 209;
  --bs-warning-rgb: 255, 195, 90;
  --bs-danger-rgb: 250, 92, 124;
  --bs-light-rgb: 238, 242, 247;
  --bs-dark-rgb: 49, 58, 70;

  --bs-tertiary-color: #313539;
  --bs-tertiary-color-rgb: 49, 53, 57;
  --bs-tertiary-bg: #f6f7fb;
  --bs-tertiary-bg-rgb: 246, 247, 251;

  --bs-heading-color: inherit;

  --bs-body-color: #6c757d;
  --bs-body-bg: #fafbfe;

  --bs-footer-height: 60px;
  --bs-emphasis-color: rgba(108, 117, 125, 0.75);

  --bs-border-color: #dee2e6;

  --bs-topbar-height: 70px;
  --bs-topbar-item-color: #6c757d;
  --bs-topbar-user-border: #eef2f7;
  --bs-topbar-user-bg: #fafafd;
  --bs-topbar-item-hover-color: #727cf5;
  --bs-topbar-bg: #ffffff;

  --bs-dropdown-item-padding-x: 1.5rem;
  --bs-dropdown-item-padding-y: 0.375rem;
  --bs-dropdown-link-color: var(--bs-body-color);

  --bs-theme-card-border-width: 0px;

  --bs-border-radius: 0.25rem;

  --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-body-color-rgb), 0.075);
  --bs-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);

  // LINK
  --bs-link-color: #727cf5;
  --bs-link-color-rgb: 114, 124, 245;
  --bs-link-decoration: none;
  --bs-link-hover-color: #6169d0;
  --bs-link-hover-color-rgb: 97, 105, 208;

  // PROGRESS
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.675rem;
  --bs-progress-bg: var(--bs-tertiary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #727cf5;
  --bs-progress-bar-transition: width 0.6s ease;

  // CARD
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 0.75rem;
  --bs-card-border-width: var(--bs-theme-card-border-width);
  --bs-card-border-color: var(--bs-border-color);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: var(--bs-box-shadow);
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-theme-card-border-width)));
  --bs-card-cap-padding-y: 1.125rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-card-cap-bg: transparent;
  --bs-card-bg: var(--bs-secondary-bg);
  --bs-card-img-overlay-padding: 1.5rem;
  --bs-card-group-margin: 0.75rem;

  // BAD
  --bs-badge-padding-x: 0.4em;
  --bs-badge-padding-y: 0.25em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.25rem;

  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #dee2e6;
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.2rem;
  --bs-border-radius-lg: 0.3rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;

  --bs-btn-close-color: --bs-secondary-color;

  --bs-primary-text-emphasis: #2e3262;
  --bs-primary-bg-subtle: #f1f2fe;
  --bs-primary-border-subtle: #e3e5fd;
  --bs-primary-text-emphasis: #2e3262;
}

body {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
}
