.account-pages {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: var(--bs-footer-height);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.5rem;
  color: var(--bs-emphasis-color);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  border-top: 1px solid var(--bs-border-color);
}

.footer-alt {
  border: none;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
