.navbar-custom {
  margin: 0;
  padding: 0;
  z-index: 1005;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-custom {
  background: var(--bs-topbar-bg);
  -webkit-box-shadow: var(--bs-box-shadow);
  box-shadow: var(--bs-box-shadow);
  min-height: var(--bs-topbar-height);
  margin-left: var(--bs-leftbar-width);
  position: sticky;
  top: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 1000;
  border-bottom: var(--bs-theme-card-border-width) solid var(--bs-border-color);
}

.navbar-custom .topbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

.navbar-custom .logo-topbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  margin-right: 15px;

  img {
    max-height: 50px;
    max-width: 50px;
    margin-right: 5px;
    margin-top: -10px;
  }

  @media screen and (max-width: 768px) {
    margin-right: 10px;
    img {
      display: none;
    }
  }
}

.navbar-custom .topbar-menu {
  position: relative;
  list-style-type: none;
  margin-bottom: 0;
}

.navbar-custom .topbar-menu li {
  max-height: var(--bs-topbar-height);
}

.navbar-custom .topbar-menu .nav-link {
  padding: 0;
  position: relative;
  color: var(--bs-topbar-item-color);
  background-color: transparent;
  border-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: var(--bs-topbar-height);
  cursor: pointer;

  &:hover {
    color: var(--bs-topbar-item-hover-color);
  }
}

.navbar-custom .logo-txt {
  font-size: 1.2rem;
  font-weight: 500;
  color: black;
  margin: 0;
  padding: 0;
  line-height: var(--bs-topbar-height);
  font-family: 'Poppins', sans-serif;
}

.nav-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  border-width: 0 1px;
  border-style: solid;
  border-color: var(--bs-topbar-user-border);
  background-color: var(--bs-topbar-user-bg);
  min-height: var(--bs-topbar-height);
  -webkit-transition: none;
  transition: none;
}

.profile-dropdown {
  min-width: 170px;
}
