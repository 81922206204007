.btn {
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: 0px 2px 6px 0px;
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.15rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-light {
  --bs-btn-color: var(--bs-dark);
  --bs-btn-bg: var(--bs-light);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-tertiary-color);
  --bs-btn-hover-bg: var(--bs-tertiary-bg);
  --bs-btn-hover-border-color: var(--bs-tertiary-bg);
  --bs-btn-active-bg: var(--bs-tertiary-bg);
  --bs-btn-active-color: var(--bs-tertiary-color);
  --bs-btn-active-border-color: var(--bs-tertiary-bg);
  --bs-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--bs-light-rgb), 0.5);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #727cf5;
  --bs-btn-border-color: #727cf5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6973e3;
  --bs-btn-hover-border-color: #6973e3;
  --bs-btn-focus-shadow-rgb: 135, 144, 247;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6973e3;
  --bs-btn-active-border-color: #6973e3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #727cf5;
  --bs-btn-disabled-border-color: #727cf5;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0acf97;
  --bs-btn-border-color: #0acf97;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #09bf8c;
  --bs-btn-hover-border-color: #09bf8c;
  --bs-btn-focus-shadow-rgb: 47, 214, 167;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #09bf8c;
  --bs-btn-active-border-color: #09bf8c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0acf97;
  --bs-btn-disabled-border-color: #0acf97;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #646c74;
  --bs-btn-hover-border-color: #646c74;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #646c74;
  --bs-btn-active-border-color: #646c74;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ffc35a;
  --bs-btn-border-color: #ffc35a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ecb453;
  --bs-btn-hover-border-color: #ecb453;
  --bs-btn-focus-shadow-rgb: 255, 204, 115;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ecb453;
  --bs-btn-active-border-color: #ecb453;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ffc35a;
  --bs-btn-disabled-border-color: #ffc35a;
}

.btn-outline-primary {
  --bs-btn-color: #727cf5;
  --bs-btn-border-color: #727cf5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #727cf5;
  --bs-btn-hover-border-color: #727cf5;
  --bs-btn-focus-shadow-rgb: 114, 124, 245;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #727cf5;
  --bs-btn-active-border-color: #727cf5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #727cf5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #727cf5;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #fa5c7c;
  --bs-btn-border-color: #fa5c7c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fa5c7c;
  --bs-btn-hover-border-color: #fa5c7c;
  --bs-btn-focus-shadow-rgb: 250, 92, 124;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fa5c7c;
  --bs-btn-active-border-color: #fa5c7c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fa5c7c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fa5c7c;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: var(--bs-dark);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--bs-light);
  --bs-btn-hover-color: var(--bs-tertiary-color);
  --bs-btn-hover-bg: var(--bs-tertiary-bg);
  --bs-btn-hover-border-color: var(--bs-tertiary-bg);
  --bs-btn-active-bg: var(--bs-tertiary-bg);
  --bs-btn-active-color: var(--bs-tertiary-color);
  --bs-btn-active-border-color: var(--bs-tertiary-bg);
  --bs-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--bs-light-rgb), 0.5);
}

.btn-outline-dark {
  --bs-btn-color: var(--bs-dark);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--bs-dark);
  --bs-btn-hover-color: var(--bs-light);
  --bs-btn-hover-bg: var(--bs-dark);
  --bs-btn-hover-border-color: var(--bs-dark);
  --bs-btn-active-bg: var(--bs-dark);
  --bs-btn-active-color: var(--bs-light);
  --bs-btn-active-border-color: var(--bs-dark);
  --bs-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--bs-dark-rgb), 0.5);
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}
